import { default as _91choice_93020ve228evMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/aanbod-optie/[propertyId]/[hash]/[choice].vue?macro=true";
import { default as index2kG8EMyLbeMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/aanbod/index.vue?macro=true";
import { default as indexUFkVds7EmQMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/fonds-optie/[property_id]/[hash]/[type]/index.vue?macro=true";
import { default as indexvqs7OqLuHqMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/index.vue?macro=true";
import { default as indexH8zbena4BZMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/login/index.vue?macro=true";
import { default as reset_45wachtwoordvJjRpxd2M1Meta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/login/reset-wachtwoord.vue?macro=true";
import { default as wachtwoord_45vergetenOKlQGvTu0gMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/login/wachtwoord-vergeten.vue?macro=true";
import { default as indextmZBvL3Q5bMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-aanvragen/[offerNumber]/herkomst/index.vue?macro=true";
import { default as indexGOhNOcJS4ZMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-aanvragen/[offerNumber]/index.vue?macro=true";
import { default as indexxglBe9OUGVMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-aanvragen/index.vue?macro=true";
import { default as indexhrh8LOnzWeMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-organisaties/[companyId]/iban-overzicht/index.vue?macro=true";
import { default as indexRqBLJ4clXNMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-organisaties/bedrijfs-gegevens/index.vue?macro=true";
import { default as indexKolorRtoNNMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-organisaties/index.vue?macro=true";
import { default as indexlQ0LlVpLZQMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-partnerplan/[token]/[choice]/index.vue?macro=true";
import { default as indexqkxG5mmoyaMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-partnerplan/index.vue?macro=true";
import { default as indexPxkVeKu5SiMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-profiel/digitaal-identificeren/index.vue?macro=true";
import { default as indexId2QMFTQOXMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-profiel/iban-overzicht/index.vue?macro=true";
import { default as indexO4p6b9YukPMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-profiel/index.vue?macro=true";
import { default as indexLxZlGYka5sMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-profiel/naw-gegevens/index.vue?macro=true";
import { default as indexkrMhxFaiWpMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mobile/index.vue?macro=true";
import { default as indexcUJfMuRDUkMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mogelijk-fonds/[id]/deelname/index.vue?macro=true";
import { default as indexBB6K4hj1DlMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mogelijk-fonds/[id]/documenten/index.vue?macro=true";
import { default as indexgJqiduaLn8Meta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mogelijk-fonds/[id]/index.vue?macro=true";
import { default as index9JTla4J3C4Meta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mogelijk-fonds/[id]/inschrijving/index.vue?macro=true";
import { default as indexmh7Ndau9kmMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mogelijk-fonds/[id]/ondertekenen/index.vue?macro=true";
import { default as indexgXnD9o7KdCMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mogelijk-fonds/[id]/overmaken/index.vue?macro=true";
import { default as indexP8vFrVvY8NMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mogelijk-fonds/index.vue?macro=true";
import { default as errorjB6jQc2zFOMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/ockto/[hash]/error.vue?macro=true";
import { default as indexNHmV1NlXBIMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/ockto/[hash]/index.vue?macro=true";
import { default as qrcodeO8sQsIF1GDMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/ockto/[hash]/qrcode.vue?macro=true";
import { default as succesuhmibVktOBMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/ockto/[hash]/succes.vue?macro=true";
import { default as _91token_93UYSZCFwqXbMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/onboarding/[signing_id]/[token].vue?macro=true";
import { default as _91slug_936U9pgiHnKkMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/onboarding/[slug].vue?macro=true";
import { default as aanvraag6BdTNZWBeqMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/onboarding/aanvraag.vue?macro=true";
import { default as _91experience_93LRLB2skYviMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/onboarding/bedankt/[experience].vue?macro=true";
import { default as _91token_93Iea5zHLGLYMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/onfido/[token].vue?macro=true";
import { default as _91hash_93P3ls4yqExfMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/passeren/[hash].vue?macro=true";
import { default as index6zW5eO1AgfMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/privacy-verklaring/index.vue?macro=true";
import { default as _91fundKey_93WpXRcVXOjaMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/sso/[exchangeToken]/[fundKey].vue?macro=true";
import { default as aanvraagYtCacRaotfMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/sso/[exchangeToken]/onboarding/aanvraag.vue?macro=true";
import { default as indexhxoRIHF8vmMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/sso/[exchangeToken]/onboarding/index.vue?macro=true";
import { default as _91id_93tKAbo4EwwNMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/sso/[token]/[id].vue?macro=true";
import { default as indexFUDUVZCyHZMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/sso/[token]/index.vue?macro=true";
import { default as _91hash_93mMDfIhciTtMeta } from "/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/verzekeringen/[hash].vue?macro=true";
export default [
  {
    name: _91choice_93020ve228evMeta?.name ?? "aanbod-optie-propertyId-hash-choice",
    path: _91choice_93020ve228evMeta?.path ?? "/aanbod-optie/:propertyId()/:hash()/:choice()",
    meta: _91choice_93020ve228evMeta || {},
    alias: _91choice_93020ve228evMeta?.alias || [],
    redirect: _91choice_93020ve228evMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/aanbod-optie/[propertyId]/[hash]/[choice].vue").then(m => m.default || m)
  },
  {
    name: index2kG8EMyLbeMeta?.name ?? "aanbod",
    path: index2kG8EMyLbeMeta?.path ?? "/aanbod",
    meta: index2kG8EMyLbeMeta || {},
    alias: index2kG8EMyLbeMeta?.alias || [],
    redirect: index2kG8EMyLbeMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/aanbod/index.vue").then(m => m.default || m)
  },
  {
    name: indexUFkVds7EmQMeta?.name ?? "fonds-optie-property_id-hash-type",
    path: indexUFkVds7EmQMeta?.path ?? "/fonds-optie/:property_id()/:hash()/:type()",
    meta: indexUFkVds7EmQMeta || {},
    alias: indexUFkVds7EmQMeta?.alias || [],
    redirect: indexUFkVds7EmQMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/fonds-optie/[property_id]/[hash]/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvqs7OqLuHqMeta?.name ?? "index",
    path: indexvqs7OqLuHqMeta?.path ?? "/",
    meta: indexvqs7OqLuHqMeta || {},
    alias: indexvqs7OqLuHqMeta?.alias || [],
    redirect: indexvqs7OqLuHqMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexH8zbena4BZMeta?.name ?? "login",
    path: indexH8zbena4BZMeta?.path ?? "/login",
    meta: indexH8zbena4BZMeta || {},
    alias: indexH8zbena4BZMeta?.alias || [],
    redirect: indexH8zbena4BZMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45wachtwoordvJjRpxd2M1Meta?.name ?? "login-reset-wachtwoord",
    path: reset_45wachtwoordvJjRpxd2M1Meta?.path ?? "/login/reset-wachtwoord",
    meta: reset_45wachtwoordvJjRpxd2M1Meta || {},
    alias: reset_45wachtwoordvJjRpxd2M1Meta?.alias || [],
    redirect: reset_45wachtwoordvJjRpxd2M1Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/login/reset-wachtwoord.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45vergetenOKlQGvTu0gMeta?.name ?? "login-wachtwoord-vergeten",
    path: wachtwoord_45vergetenOKlQGvTu0gMeta?.path ?? "/login/wachtwoord-vergeten",
    meta: wachtwoord_45vergetenOKlQGvTu0gMeta || {},
    alias: wachtwoord_45vergetenOKlQGvTu0gMeta?.alias || [],
    redirect: wachtwoord_45vergetenOKlQGvTu0gMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/login/wachtwoord-vergeten.vue").then(m => m.default || m)
  },
  {
    name: indextmZBvL3Q5bMeta?.name ?? "mijn-aanvragen-offerNumber-herkomst",
    path: indextmZBvL3Q5bMeta?.path ?? "/mijn-aanvragen/:offerNumber()/herkomst",
    meta: indextmZBvL3Q5bMeta || {},
    alias: indextmZBvL3Q5bMeta?.alias || [],
    redirect: indextmZBvL3Q5bMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-aanvragen/[offerNumber]/herkomst/index.vue").then(m => m.default || m)
  },
  {
    name: indexGOhNOcJS4ZMeta?.name ?? "mijn-aanvragen-offerNumber",
    path: indexGOhNOcJS4ZMeta?.path ?? "/mijn-aanvragen/:offerNumber()",
    meta: indexGOhNOcJS4ZMeta || {},
    alias: indexGOhNOcJS4ZMeta?.alias || [],
    redirect: indexGOhNOcJS4ZMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-aanvragen/[offerNumber]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxglBe9OUGVMeta?.name ?? "mijn-aanvragen",
    path: indexxglBe9OUGVMeta?.path ?? "/mijn-aanvragen",
    meta: indexxglBe9OUGVMeta || {},
    alias: indexxglBe9OUGVMeta?.alias || [],
    redirect: indexxglBe9OUGVMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-aanvragen/index.vue").then(m => m.default || m)
  },
  {
    name: indexhrh8LOnzWeMeta?.name ?? "mijn-organisaties-companyId-iban-overzicht",
    path: indexhrh8LOnzWeMeta?.path ?? "/mijn-organisaties/:companyId()/iban-overzicht",
    meta: indexhrh8LOnzWeMeta || {},
    alias: indexhrh8LOnzWeMeta?.alias || [],
    redirect: indexhrh8LOnzWeMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-organisaties/[companyId]/iban-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexRqBLJ4clXNMeta?.name ?? "mijn-organisaties-bedrijfs-gegevens",
    path: indexRqBLJ4clXNMeta?.path ?? "/mijn-organisaties/bedrijfs-gegevens",
    meta: indexRqBLJ4clXNMeta || {},
    alias: indexRqBLJ4clXNMeta?.alias || [],
    redirect: indexRqBLJ4clXNMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-organisaties/bedrijfs-gegevens/index.vue").then(m => m.default || m)
  },
  {
    name: indexKolorRtoNNMeta?.name ?? "mijn-organisaties",
    path: indexKolorRtoNNMeta?.path ?? "/mijn-organisaties",
    meta: indexKolorRtoNNMeta || {},
    alias: indexKolorRtoNNMeta?.alias || [],
    redirect: indexKolorRtoNNMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-organisaties/index.vue").then(m => m.default || m)
  },
  {
    name: indexlQ0LlVpLZQMeta?.name ?? "mijn-partnerplan-token-choice",
    path: indexlQ0LlVpLZQMeta?.path ?? "/mijn-partnerplan/:token()/:choice()",
    meta: indexlQ0LlVpLZQMeta || {},
    alias: indexlQ0LlVpLZQMeta?.alias || [],
    redirect: indexlQ0LlVpLZQMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-partnerplan/[token]/[choice]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqkxG5mmoyaMeta?.name ?? "mijn-partnerplan",
    path: indexqkxG5mmoyaMeta?.path ?? "/mijn-partnerplan",
    meta: indexqkxG5mmoyaMeta || {},
    alias: indexqkxG5mmoyaMeta?.alias || [],
    redirect: indexqkxG5mmoyaMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-partnerplan/index.vue").then(m => m.default || m)
  },
  {
    name: indexPxkVeKu5SiMeta?.name ?? "mijn-profiel-digitaal-identificeren",
    path: indexPxkVeKu5SiMeta?.path ?? "/mijn-profiel/digitaal-identificeren",
    meta: indexPxkVeKu5SiMeta || {},
    alias: indexPxkVeKu5SiMeta?.alias || [],
    redirect: indexPxkVeKu5SiMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-profiel/digitaal-identificeren/index.vue").then(m => m.default || m)
  },
  {
    name: indexId2QMFTQOXMeta?.name ?? "mijn-profiel-iban-overzicht",
    path: indexId2QMFTQOXMeta?.path ?? "/mijn-profiel/iban-overzicht",
    meta: indexId2QMFTQOXMeta || {},
    alias: indexId2QMFTQOXMeta?.alias || [],
    redirect: indexId2QMFTQOXMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-profiel/iban-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexO4p6b9YukPMeta?.name ?? "mijn-profiel",
    path: indexO4p6b9YukPMeta?.path ?? "/mijn-profiel",
    meta: indexO4p6b9YukPMeta || {},
    alias: indexO4p6b9YukPMeta?.alias || [],
    redirect: indexO4p6b9YukPMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-profiel/index.vue").then(m => m.default || m)
  },
  {
    name: indexLxZlGYka5sMeta?.name ?? "mijn-profiel-naw-gegevens",
    path: indexLxZlGYka5sMeta?.path ?? "/mijn-profiel/naw-gegevens",
    meta: indexLxZlGYka5sMeta || {},
    alias: indexLxZlGYka5sMeta?.alias || [],
    redirect: indexLxZlGYka5sMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mijn-profiel/naw-gegevens/index.vue").then(m => m.default || m)
  },
  {
    name: indexkrMhxFaiWpMeta?.name ?? "mobile",
    path: indexkrMhxFaiWpMeta?.path ?? "/mobile",
    meta: indexkrMhxFaiWpMeta || {},
    alias: indexkrMhxFaiWpMeta?.alias || [],
    redirect: indexkrMhxFaiWpMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mobile/index.vue").then(m => m.default || m)
  },
  {
    name: indexcUJfMuRDUkMeta?.name ?? "mogelijk-fonds-id-deelname",
    path: indexcUJfMuRDUkMeta?.path ?? "/mogelijk-fonds/:id()/deelname",
    meta: indexcUJfMuRDUkMeta || {},
    alias: indexcUJfMuRDUkMeta?.alias || [],
    redirect: indexcUJfMuRDUkMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mogelijk-fonds/[id]/deelname/index.vue").then(m => m.default || m)
  },
  {
    name: indexBB6K4hj1DlMeta?.name ?? "mogelijk-fonds-id-documenten",
    path: indexBB6K4hj1DlMeta?.path ?? "/mogelijk-fonds/:id()/documenten",
    meta: indexBB6K4hj1DlMeta || {},
    alias: indexBB6K4hj1DlMeta?.alias || [],
    redirect: indexBB6K4hj1DlMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mogelijk-fonds/[id]/documenten/index.vue").then(m => m.default || m)
  },
  {
    name: indexgJqiduaLn8Meta?.name ?? "mogelijk-fonds-id",
    path: indexgJqiduaLn8Meta?.path ?? "/mogelijk-fonds/:id()",
    meta: indexgJqiduaLn8Meta || {},
    alias: indexgJqiduaLn8Meta?.alias || [],
    redirect: indexgJqiduaLn8Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mogelijk-fonds/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index9JTla4J3C4Meta?.name ?? "mogelijk-fonds-id-inschrijving",
    path: index9JTla4J3C4Meta?.path ?? "/mogelijk-fonds/:id()/inschrijving",
    meta: index9JTla4J3C4Meta || {},
    alias: index9JTla4J3C4Meta?.alias || [],
    redirect: index9JTla4J3C4Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mogelijk-fonds/[id]/inschrijving/index.vue").then(m => m.default || m)
  },
  {
    name: indexmh7Ndau9kmMeta?.name ?? "mogelijk-fonds-id-ondertekenen",
    path: indexmh7Ndau9kmMeta?.path ?? "/mogelijk-fonds/:id()/ondertekenen",
    meta: indexmh7Ndau9kmMeta || {},
    alias: indexmh7Ndau9kmMeta?.alias || [],
    redirect: indexmh7Ndau9kmMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mogelijk-fonds/[id]/ondertekenen/index.vue").then(m => m.default || m)
  },
  {
    name: indexgXnD9o7KdCMeta?.name ?? "mogelijk-fonds-id-overmaken",
    path: indexgXnD9o7KdCMeta?.path ?? "/mogelijk-fonds/:id()/overmaken",
    meta: indexgXnD9o7KdCMeta || {},
    alias: indexgXnD9o7KdCMeta?.alias || [],
    redirect: indexgXnD9o7KdCMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mogelijk-fonds/[id]/overmaken/index.vue").then(m => m.default || m)
  },
  {
    name: indexP8vFrVvY8NMeta?.name ?? "mogelijk-fonds",
    path: indexP8vFrVvY8NMeta?.path ?? "/mogelijk-fonds",
    meta: indexP8vFrVvY8NMeta || {},
    alias: indexP8vFrVvY8NMeta?.alias || [],
    redirect: indexP8vFrVvY8NMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/mogelijk-fonds/index.vue").then(m => m.default || m)
  },
  {
    name: errorjB6jQc2zFOMeta?.name ?? "ockto-hash-error",
    path: errorjB6jQc2zFOMeta?.path ?? "/ockto/:hash()/error",
    meta: errorjB6jQc2zFOMeta || {},
    alias: errorjB6jQc2zFOMeta?.alias || [],
    redirect: errorjB6jQc2zFOMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/ockto/[hash]/error.vue").then(m => m.default || m)
  },
  {
    name: indexNHmV1NlXBIMeta?.name ?? "ockto-hash",
    path: indexNHmV1NlXBIMeta?.path ?? "/ockto/:hash()",
    meta: indexNHmV1NlXBIMeta || {},
    alias: indexNHmV1NlXBIMeta?.alias || [],
    redirect: indexNHmV1NlXBIMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/ockto/[hash]/index.vue").then(m => m.default || m)
  },
  {
    name: qrcodeO8sQsIF1GDMeta?.name ?? "ockto-hash-qrcode",
    path: qrcodeO8sQsIF1GDMeta?.path ?? "/ockto/:hash()/qrcode",
    meta: qrcodeO8sQsIF1GDMeta || {},
    alias: qrcodeO8sQsIF1GDMeta?.alias || [],
    redirect: qrcodeO8sQsIF1GDMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/ockto/[hash]/qrcode.vue").then(m => m.default || m)
  },
  {
    name: succesuhmibVktOBMeta?.name ?? "ockto-hash-succes",
    path: succesuhmibVktOBMeta?.path ?? "/ockto/:hash()/succes",
    meta: succesuhmibVktOBMeta || {},
    alias: succesuhmibVktOBMeta?.alias || [],
    redirect: succesuhmibVktOBMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/ockto/[hash]/succes.vue").then(m => m.default || m)
  },
  {
    name: _91token_93UYSZCFwqXbMeta?.name ?? "onboarding-signing_id-token",
    path: _91token_93UYSZCFwqXbMeta?.path ?? "/onboarding/:signing_id()/:token()",
    meta: _91token_93UYSZCFwqXbMeta || {},
    alias: _91token_93UYSZCFwqXbMeta?.alias || [],
    redirect: _91token_93UYSZCFwqXbMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/onboarding/[signing_id]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936U9pgiHnKkMeta?.name ?? "onboarding-slug",
    path: _91slug_936U9pgiHnKkMeta?.path ?? "/onboarding/:slug()",
    meta: _91slug_936U9pgiHnKkMeta || {},
    alias: _91slug_936U9pgiHnKkMeta?.alias || [],
    redirect: _91slug_936U9pgiHnKkMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/onboarding/[slug].vue").then(m => m.default || m)
  },
  {
    name: aanvraag6BdTNZWBeqMeta?.name ?? "onboarding-aanvraag",
    path: aanvraag6BdTNZWBeqMeta?.path ?? "/onboarding/aanvraag",
    meta: aanvraag6BdTNZWBeqMeta || {},
    alias: aanvraag6BdTNZWBeqMeta?.alias || [],
    redirect: aanvraag6BdTNZWBeqMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/onboarding/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: _91experience_93LRLB2skYviMeta?.name ?? "onboarding-bedankt-experience",
    path: _91experience_93LRLB2skYviMeta?.path ?? "/onboarding/bedankt/:experience()",
    meta: _91experience_93LRLB2skYviMeta || {},
    alias: _91experience_93LRLB2skYviMeta?.alias || [],
    redirect: _91experience_93LRLB2skYviMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/onboarding/bedankt/[experience].vue").then(m => m.default || m)
  },
  {
    name: _91token_93Iea5zHLGLYMeta?.name ?? "onfido-token",
    path: _91token_93Iea5zHLGLYMeta?.path ?? "/onfido/:token()",
    meta: _91token_93Iea5zHLGLYMeta || {},
    alias: _91token_93Iea5zHLGLYMeta?.alias || [],
    redirect: _91token_93Iea5zHLGLYMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/onfido/[token].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93P3ls4yqExfMeta?.name ?? "passeren-hash",
    path: _91hash_93P3ls4yqExfMeta?.path ?? "/passeren/:hash()",
    meta: _91hash_93P3ls4yqExfMeta || {},
    alias: _91hash_93P3ls4yqExfMeta?.alias || [],
    redirect: _91hash_93P3ls4yqExfMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/passeren/[hash].vue").then(m => m.default || m)
  },
  {
    name: index6zW5eO1AgfMeta?.name ?? "privacy-verklaring",
    path: index6zW5eO1AgfMeta?.path ?? "/privacy-verklaring",
    meta: index6zW5eO1AgfMeta || {},
    alias: index6zW5eO1AgfMeta?.alias || [],
    redirect: index6zW5eO1AgfMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/privacy-verklaring/index.vue").then(m => m.default || m)
  },
  {
    name: _91fundKey_93WpXRcVXOjaMeta?.name ?? "sso-exchangeToken-fundKey",
    path: _91fundKey_93WpXRcVXOjaMeta?.path ?? "/sso/:exchangeToken()/:fundKey()",
    meta: _91fundKey_93WpXRcVXOjaMeta || {},
    alias: _91fundKey_93WpXRcVXOjaMeta?.alias || [],
    redirect: _91fundKey_93WpXRcVXOjaMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/sso/[exchangeToken]/[fundKey].vue").then(m => m.default || m)
  },
  {
    name: aanvraagYtCacRaotfMeta?.name ?? "sso-exchangeToken-onboarding-aanvraag",
    path: aanvraagYtCacRaotfMeta?.path ?? "/sso/:exchangeToken()/onboarding/aanvraag",
    meta: aanvraagYtCacRaotfMeta || {},
    alias: aanvraagYtCacRaotfMeta?.alias || [],
    redirect: aanvraagYtCacRaotfMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/sso/[exchangeToken]/onboarding/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: indexhxoRIHF8vmMeta?.name ?? "sso-exchangeToken-onboarding",
    path: indexhxoRIHF8vmMeta?.path ?? "/sso/:exchangeToken()/onboarding",
    meta: indexhxoRIHF8vmMeta || {},
    alias: indexhxoRIHF8vmMeta?.alias || [],
    redirect: indexhxoRIHF8vmMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/sso/[exchangeToken]/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93tKAbo4EwwNMeta?.name ?? "sso-token-id",
    path: _91id_93tKAbo4EwwNMeta?.path ?? "/sso/:token()/:id()",
    meta: _91id_93tKAbo4EwwNMeta || {},
    alias: _91id_93tKAbo4EwwNMeta?.alias || [],
    redirect: _91id_93tKAbo4EwwNMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/sso/[token]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexFUDUVZCyHZMeta?.name ?? "sso-token",
    path: indexFUDUVZCyHZMeta?.path ?? "/sso/:token()",
    meta: indexFUDUVZCyHZMeta || {},
    alias: indexFUDUVZCyHZMeta?.alias || [],
    redirect: indexFUDUVZCyHZMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/sso/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93mMDfIhciTtMeta?.name ?? "verzekeringen-hash",
    path: _91hash_93mMDfIhciTtMeta?.path ?? "/verzekeringen/:hash()",
    meta: _91hash_93mMDfIhciTtMeta || {},
    alias: _91hash_93mMDfIhciTtMeta?.alias || [],
    redirect: _91hash_93mMDfIhciTtMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241211091122/packages/investors/pages/verzekeringen/[hash].vue").then(m => m.default || m)
  }
]