import dayjs from 'dayjs';

import { computed, reactive } from 'vue';
import { defineStore } from 'pinia';
import { useAuthStore } from '@stores/useAuthStore';
import { FundStatuses } from '@graphql/enums/fundStatuses';
import { useUrqlStore } from '@stores/useUrqlStore';
import { FUND, FUNDS } from '@graphql/queries/fund';
import { OperationResult } from '@urql/core';

import type { Fund, FundResponse, FundsResponse } from '@graphql/types/fund';

interface Funds {
  data: Fund[] | undefined;
  currentFund: Fund | null;
  error: boolean;
  fetching: boolean;
  isFundPageLoading: boolean;
}

export const storeSetup = () => {
  const { isLoading, authenticated } = toRefs(useAuthStore());

  const funds: Funds = reactive({
    data: undefined,
    currentFund: null,
    error: false,
    fetching: false,
    isFundPageLoading: false,
  });

  const getFund = async (id: string) => {
    const { gqlClient } = toRefs(useUrqlStore());

    return gqlClient.value
      .query<FundResponse>(FUND, { id }, {
        requestPolicy: 'network-only',
      })
      .toPromise()
      .then(result => {
        if (result.data) {
          return result.data.fund;
        }

        if (result.error) {
          // eslint-disable-next-line unicorn/error-message
          throw new Error();
        }

        return null;
      });
  };

  const getFunds = async () => {
    funds.fetching = true;

    const { gqlClient } = toRefs(useUrqlStore());

    await gqlClient.value
      .query<FundsResponse>(FUNDS, undefined)
      .toPromise()
      .then((result: OperationResult<FundsResponse>) => {
        funds.data = result.data?.funds;
        funds.error = false;

        if (result.error) {
          // eslint-disable-next-line unicorn/error-message
          throw new Error();
        }
      })
      .catch(() => {
        funds.error = true;
      })
      .finally(() => {
        funds.fetching = false;
      });

    if (!isLoading && !authenticated) {
      funds.data = undefined;
    }
  };

  const getOpenForRegistrationsFund = (fund: Fund): boolean => {
    return !!fund && (fund.status === FundStatuses.REGISTRATION || fund.status === FundStatuses.COLLECT);
  };

  const onGoingFund = (fund: Fund): boolean => {
    return getOpenForRegistrationsFund(fund) || (!!fund && fund.status === FundStatuses.ACTIVE);
  };

  const setCurrentFund = (fund: Fund) => {
    funds.currentFund = fund;
  };

  const resetCurrentFund = () => {
    funds.currentFund = null;
  };

  const setFundPageLoading = (isFetching: boolean) => {
    funds.isFundPageLoading = isFetching;
  };

  const setCurrentFundProjectedReturn = (projected_return: number) => {
    if (funds.currentFund) funds.currentFund.projected_return = projected_return;
  };

  const getFundDuration = (fund: Fund | null): number => {
    const start = dayjs(fund?.start_date);
    const end = dayjs(fund?.end_date);

    return end.diff(start, 'year');
  };

  const openForRegistrationsFunds = computed(() => (funds.data?.length ? funds.data?.filter(fund => getOpenForRegistrationsFund(fund)) : []));
  const onGoingFunds = computed(() => (funds.data?.length ? funds.data?.filter(fund => onGoingFund(fund)) : []));
  const newestFund = computed(() => openForRegistrationsFunds.value[0]);
  const currentFund = computed(() => funds.currentFund);
  const isFundPageLoading = computed(() => funds.isFundPageLoading);

  return {
    // state
    funds,
    isFundPageLoading,
    openForRegistrationsFunds,
    onGoingFunds,
    newestFund,
    currentFund,

    // actions
    getFund,
    getFunds,
    getFundDuration,
    getOpenForRegistrationsFund,
    setCurrentFund,
    setFundPageLoading,
    setCurrentFundProjectedReturn,
    resetCurrentFund,
  };
};

export const useFundStore = defineStore('fund', storeSetup, {
  persistedState: {
    persist: false,
  },
});
