import { defineStore } from 'pinia';
import { toRefs } from 'vue';
import { createClient, fetchExchange } from '@urql/core';
import { useAuthStore } from '@stores/useAuthStore';

export const storeSetup = () => {
  const { accessToken } = toRefs(useAuthStore());

  const gqlClient = createClient({
    url: import.meta.env.VITE_PROXY_URL ?? '',
    requestPolicy: 'network-only',
    fetchOptions: () => ({
      cache: 'no-cache',
      headers: {
        authorization: `Bearer ${accessToken?.value}`,
        role: 'advisor',
        environment: import.meta.env.VITE_GRAPHQL_ENVIRONMENT,
      },
    }),
    exchanges: [
      fetchExchange,
    ],
  });

  return {
    gqlClient,
  };
};

export const useUrqlStore = defineStore('urql', storeSetup, { persistedState: { persist: false } });
