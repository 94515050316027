/* eslint-disable import/no-unresolved */
/* eslint-disable no-underscore-dangle */
import {
  createClient,
  ssrExchange,
  fetchExchange,
} from '@urql/core';
import { authExchange } from '@urql/exchange-auth';
import { cacheExchange } from '@urql/exchange-graphcache';
import type { Data } from '@urql/exchange-graphcache';
import { ref } from 'vue';
import { defineNuxtPlugin } from 'nuxt/app';
import { REFRESH_TOKEN_MUTATION } from '@graphql/mutations/auth';
import { useAuthStore } from '@stores/useAuthStore';
import type { DocumentOwner, DocumentContext } from '@graphql/types/document';
import type { OfferObject } from '@graphql/types/objects';
import type { QuestionData } from '@graphql/types/onboarding';
import { randomString, slugify } from '@mogelijk-technologies/ui-library';

interface ImageUrl {
  s: string;
  m: string;
  l: string;
  c: string;
}

const SSRKEY = '__URQL_DATA__';

interface AuthState {
  token: string;
  refreshToken?: string;
}

export default defineNuxtPlugin(nuxtApp => {
  const { vueApp } = nuxtApp;
  const auth = useAuthStore();

  const isServerSide = typeof window === 'undefined';
  const ssrCache = ssrExchange({ isClient: !isServerSide });

  if (isServerSide) {
    nuxtApp.hook('app:rendered', () => {
      nuxtApp.payload[SSRKEY] = ssrCache.extractData();
    });
  }

  const client = createClient({
    url: '/graphql',
    fetchOptions: {
      headers: {
        environment: import.meta.env.VITE_GRAPHQL_ENVIRONMENT,
      },
    },
    exchanges: [
      cacheExchange({
        keys: {
          ImageUrl: (data: Data & ImageUrl) => data.s,
          documentOwners: (data: Data & DocumentOwner) => data.owner.id,
          DocumentContext: (data: Data & DocumentContext) => data.fund_key,
          ClaimAndOption: (data: Data & OfferObject['claims_and_options']) => data.id?.toString() || randomString(16),
          professionalismQuestion: (data: Data) => data.__typename,
          professionalismQuestionTypes: (data: Data) => data.__typename,
          kycQuestions: (data: Data & QuestionData) => slugify(data.name) || randomString(16),
        },
      }),
      authExchange<AuthState>({
        async getAuth({ authState, mutate }) {
          if (!authState) {
            const token = auth.accessToken;
            const refreshToken = auth.refreshToken;

            return token && refreshToken ? { token, refreshToken } : null;
          }

          if (auth.shouldRefreshToken) {
            const { data } = await mutate(REFRESH_TOKEN_MUTATION, {
              // eslint-disable-next-line camelcase
              input: { refresh_token: auth.refreshToken },
            });

            if (data?.refreshToken) {
              auth.updateTokens(
                data.refreshToken.access_token,
                data.refreshToken.refresh_token,
                Number(data.refreshToken.expires_in),
              );

              return {
                token: data.refreshToken.access_token,
                refreshToken: data.refreshToken.refresh_token,
              };
            }
          } else if (auth.accessToken) {
            return {
              token: auth.accessToken,
              refreshToken: auth.refreshToken,
            };
          }

          return null;
        },
        addAuthToOperation({ authState, operation }) {
          if (!authState?.token || !auth.accessToken) {
            return operation;
          }

          const fetchOptions = typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {};

          return {
            ...operation,
            context: {
              ...operation.context,
              fetchOptions: () => ({
                ...fetchOptions,
                headers: {
                  ...fetchOptions.headers,
                  Authorization: `Bearer ${auth.accessToken || authState?.token}`,
                },
              }),
            },
          };
        },
        didAuthError({ error }) {
          // If logged in after initialisation of client, this will update authState!
          if (auth.accessToken) {
            return true;
          }

          // check if the error was an auth error (this can be implemented in various ways,
          // e.g. 401 or a special error code)
          return error.graphQLErrors.some(
            gqlError => gqlError.extensions?.code === 'UNAUTHORIZED', // TODO: or forbidden? check code
          );
        },
      }),
      ssrCache,
      fetchExchange,
    ],
  });
  vueApp.provide('$urql', ref(client));
});
