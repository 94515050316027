import { useAuthStore } from '@stores/useAuthStore';
import { RoleStatusEnum } from '@graphql/enums/auth';

export default defineNuxtPlugin(() => {
  const router = useRouter();
  const { nawData, user, isLoading } = toRefs(useAuthStore());

  const timeout = (ms: number) => new Promise((_, reject) => setTimeout(() => reject(), ms));
  const withTimeout = (promise: Promise<any>, ms: number) => Promise.race([ promise, timeout(ms) ]);

  const waitForSmartsupp = () => {
    return new Promise(resolve => {
      const intervalId = setInterval(() => {
        if (window.smartsupp) {
          clearInterval(intervalId);
          resolve(window.smartsupp);
        }
      }, 100);
    });
  };

  const waitForUserLoading = () => {
    return new Promise(resolve => {
      const intervalId = setInterval(() => {
        if (!isLoading.value) {
          clearInterval(intervalId);
          resolve(user.value);
        }
      }, 100);
    });
  };

  const populateSmartsuppData = () => {
    if ('smartsupp' in window && user.value) {
      window.smartsupp('name', [user.value?.first_name, user.value?.infix, user.value?.last_name].filter(value => value).join(' '));
      window.smartsupp('email', nawData.value?.emails?.[0]?.email ?? '');
      window.smartsupp('phone', nawData.value?.phone_numbers?.[0]?.phone ?? '');
      window.smartsupp('variables', {
        activeRoles: user.value?.roles?.filter(role => role.status === RoleStatusEnum.ACTIVE)?.map(role => role.type).join(', ') ?? '',
      });
    }
  };

  withTimeout(waitForSmartsupp(), 9999).then(async () => {
    if (isLoading.value) await waitForUserLoading();

    populateSmartsuppData();
  });

  router.afterEach(() => populateSmartsuppData);
});
